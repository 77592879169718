import { useLocation } from 'react-router-dom';

export function useQueryParams() {
  const location = useLocation();

  const getParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  return {
    getParam,
    search: location.search,
  };
}
