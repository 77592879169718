import { useRouter } from '_core/hooks/useRouter';
import Loading from '_core/modules/atoms/Loading';
import React, { useEffect, useReducer } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useQueryParams } from '_core/hooks/useQueryParams';
import LayoutDefault from '_core/layout/LayoutDefault';
import * as auth from '_core/store/auth.duck';

function SamlCallback(props) {
  const { t } = props;
  let { token } = useParams(); //Ex: {token}&{application}
  const { getParam } = useQueryParams();
  const router = useRouter();
  const loginContext = getParam('loginContext');
  let urlCallback = router.query.callback || '/';

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loading: true,
    authToken: false,
  });

  useEffect(() => {
    // setState({ loading: true });
    if (token !== 'error') {
      const authTokenPayload = {
        token: token,
        loginContext: loginContext,
      };
      props.login(authTokenPayload);
    }
  }, []);

  useEffect(() => {
    if (state.authToken) {
      window.location.href = urlCallback;
    }
  }, [state.authToken]);

  return (
    <>
      <LayoutDefault>
        <div>{state.loading && <Loading loadingText={t('Authenticating')} />}</div>
      </LayoutDefault>
    </>
  );
}

export default withTranslation('login')(connect(null, auth.actions)(SamlCallback));
