const DOMAIN = {
  TANGERINE_DEV: 'https://tangerine-dev1.oneclicklabs.es/',
  TANGERINE_QA: 'https://tangerine-qa1.oneclicklabs.es/',
  TANGERINE_LATEST: 'https://tangerine-latest.oneclick.es/',
  SANTILLANA_INTEGRATIONS: 'https://santillana-integrations.oneclicklabs.es/',
  SANTILLANA_DEMO: 'https://santillana-demo.oneclick.es/',
  SANTILLANA_ALFA: 'https://alfa-santillana.oneclick.es/',
  MACMILLAN: 'https://digital.macmillaneducation.es/',
  GOT_IT: 'https://gotitbyme.com/',
  MASTER: 'https://master-editora.oneclick.es/',
  TINTA_FRESCA: 'https://tinta-fresca.oneclick.es/',
  BRUNO: 'https://editorial-bruno.oneclick.es/',
  SANTILLANA_PILOTO: 'https://unoi-mx.oneclick.es/',
};

const BASE_DEV = DOMAIN.TANGERINE_DEV;

const BASE_AGNOSTIC = window.location.origin.includes('localhost') ? BASE_DEV : window.location.origin + '/';

export const defaultConfig = {
  BASE_URL_: BASE_AGNOSTIC,
  BASE_URL_GAMIFICATION: BASE_AGNOSTIC,
  API_URL: BASE_AGNOSTIC + 'api/',
  BASE_STORAGE_URL: BASE_AGNOSTIC + 'files-storage-tools/',
};
